.presentation{
    padding-top: 0;
    text-align: center;
}

.container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    max-width: 1200px;
}

.subtitle{
    color : var(--color-primary);
    font-size: 2.5rem;
}

.presentation_picture{
    width: 450px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}

.presentation_copy{
    width: 80%;
    margin: auto;
}


@media screen and (max-width: 800px) {
    .presentation_copy {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .presentation_picture {
        width: 200px; 
    }
    .subtitle {
        font-size: 2rem;
    }
}