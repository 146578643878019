.hero_wave_div {
    overflow: hidden;
}
.hero_wave_svg {
    height: 100%; 
    width: 100%;
}
.hero_wave_path {
    stroke: none; 
    fill: #fff;
}

.hero_wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
}