.hero{
    height: 100vh;
    width: 100vw;
    background-image: var(--background), url("./../../assets/Hero.jpg") ;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    background-attachment: scroll;
    background-position: center center; 
    position: relative;
}