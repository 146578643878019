.container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    max-width: 1200px;
}

.nav{
    display: flex;
    justify-content: flex-end;
    height: 70px;
    align-items: center;
    font-weight: 700;
}

.nav_items {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
    padding: 10px 15px;
    font-weight: inherit;
}

.nav_items_cta{
    border: 1px solid #fff;
}

.hero_container{
    display: flex;
    height: calc(100vh - 70px);
    align-items: center;
    color: #fff;
}

.hero_text{
    width: 80%;
    margin-bottom: 50px;
}

.hero_title {
    font-size: 3.2rem;
}

.hero_subtitle {
    font-size: 2rem;
    font-weight: 300;
    margin: 15px 0;
}

@media screen and (max-width: 800px) {
    .nav {
        justify-content: space-around;
    }
    
    .nav_items_cta {
        border: none;
    }

    .nav_items {
        font-weight: 400;
        border-bottom: 1px solid #fff;
        margin-right: 0;
    }

    .hero_texts {
        width: 100%;
        margin-bottom: 80px;
    }

    .hero_title {
        font-size: 2.5rem;
    }
    
    .hero_subtitle {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 500px) {
    .nav{
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 10px;
    }

    .hero_subtitle{
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .nav_items{
        padding: 0 10px;
        border: 0;
    }

    .hero_texts {
        text-align: center;
        margin-bottom: 100px;
    }

    .hero_title {
        font-size: 2rem;
    }

}