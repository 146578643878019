.container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding: 20px 0;
    max-width: 1200px;
}

.nav{
    display: flex;
    justify-content: flex-start;
    height: 70px;
    align-items: center;
    font-weight: 300;
}

.nav_items {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
    padding: 10px;
    font-weight: inherit;
}

.footer{
    background: var(--color-primary);
}

.footer_grid {
    display: grid;
    grid-template-columns: 3fr 2fr ;
    gap: 20px;
    align-items: center;
}

.footer_title{
    font-weight: 400;
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-align: center;
}

.footer_icons{
    display: flex;
    justify-content: space-evenly;
}

.footer_icon{
    color: inherit;
    font-size: 30px;
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width: 800px) {
    .footer_grid{
        grid-template-columns: 1fr;
    }
    .footer_contact{
        grid-row: 1/2;
    }
    .footer_title{
        font-size: 1.5rem;
    }
}