:root {
    --background: linear-gradient(135deg, #667eea7e 0%, #764ba298 100%);
    --color-primary: #667eea7e;
    --color-secondary: #764ba298;
    --boton-padding: 20px 40px;
}

@media screen and (max-width: 800px) {
    :root{
        --boton-padding: 18px 40px;
    }
}

@media screen and (max-width: 500px) {
    :root{
        --boton-padding: 18px 35px;
    }
}