.about{
    min-height: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 80px;
    justify-items: center;
    align-items: center;
}

.container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding: 80px 0;
    max-width: 1200px;
}

.subtitle{
    color : var(--color-primary);
    font-size: 2.5rem;
    padding: 10px 0;
}

.about_img{
    text-align: center;
}

.about_picture {
    max-width: 80%;
}

.about_paragraph{
    margin-bottom: 20px;
    line-height: 1.5;
    font-weight: 300;
}

@media screen and (max-width: 800px) {
    .about{
        grid-template-columns: 1fr;
    }

    .about_texts:last-child{
        grid-row: 3/4;
    }
}

@media screen and (max-width: 500px) {
    .about{
        row-gap: 60px;
    }
    .container{
        padding-top: 10px;
    }

}
