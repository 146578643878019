

.container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding: 80px 0;
    max-width: 1200px;
}

.subtitle{
    color : var(--color-primary);
    font-size: 2.5rem;
    padding: 10px 0;
}

.testimony_grid{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.testimony_item{
    width: 95%;
    margin: 0 auto;
    background: var(--color-secondary);
    box-shadow: 0 8px 10px rgba(66,66,66,.5);
    border-radius: 7px;
    padding: 30px 25px;
    color: #fff;
    margin-bottom: 50px;
}

.testimony_person{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.testimony_img{
    width: 100px;
    min-width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: right;
    border-radius: 50%;
    border: 3px solid #fff;
    margin-right: 30px;
}

.testimony_name {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.testimony_verification{
    color: #fff;
    font-weight: 700;
}

.testimony_review{
    font-weight: 300;
}

@media screen and (max-width: 800px) {
    .testimony_grid{
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media screen and (max-width: 500px) {
    .testimony_item{
        padding: 30px 15px;
    }

    .testimony_texts {
        text-align: center;
    }

    .testimony_person {
        flex-direction: column;
    }

    .testimony_img {
        margin: 0;
        margin-bottom: 10px;
    }
}