.projects{
    background: #f2f2f2
}

.container {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding: 80px 0;
    max-width: 1200px;
}

.subtitle{
    color : var(--color-primary);
    font-size: 2.5rem;
    padding: 10px 0;
}

.projects_grid{
    display: grid;
    height: 550px;
    grid-template-areas: 
    "img1 img1 img2 img3"
    "img1 img1 img4 img5";
    gap: 10px;
}

.projects_item{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.projects_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.projects_item:nth-of-type(1){
    grid-area: img1;
}
.projects_item:nth-of-type(2){
    grid-area: img2;
}
.projects_item:nth-of-type(3){
    grid-area: img3;
}
.projects_item:nth-of-type(4){
    grid-area: img4;
}
.projects_item:nth-of-type(5){
    grid-area: img5;
}

.projects_hover{
    position: absolute;
    background: #dc143c83;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(101%);
    cursor: pointer;
    transition: transform .2s ease-in-out ;
}

.projects_item:hover .projects_hover{
    transform: translate(0%);
}

.projects_icon{
    margin-top: 10px;
    font-size: 30px;
}

@media screen and (max-width: 800px) {
    .projects_grid{
        grid-template-areas: 
        "img1 img1 img2 img2"
        "img1 img1 img3 img3"
        "img4 img4 img5 img5";
    }
}

@media screen and (max-width: 500px) {
    .projects_grid{
        grid-template-areas: 
        "img1"
        "img2"
        "img3"
        "img4"
        "img5";
        height: auto;
        grid-template-rows: repeat(5, 250px);
        grid-auto-rows: 250px;
    }
}